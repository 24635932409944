<template>
  <div class="movers-content">
    <div class="container" v-if="!loading">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <span class="sponsored" v-if="mover.promoted">Sponsored</span>
              <img
                :src="mover.logo"
                alt="logo"
                style="max-height: 100%; max-width: 100%"
              />
              <table class="table table-sm table-bordered">
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>{{ mover.email }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{{ mover.phone_number }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-9">
              <h1>{{ mover.name }}</h1>
              <rating />
              <div class="regions" style="margin-top: 1rem">
                <a
                  href="#"
                  class="btn btn-default"
                  v-for="(region, i) in mover.regions"
                  :key="`mover-${i}`"
                  @click.prevent=""
                  >{{ region }}</a
                >
              </div>
              <div class="socials mt-2 mb-2">
                <ul class="nav nav-pills">
                  <li v-for="(url, site) in mover.socials" :key="`socials-${site}`">
                    <a :href="url" target="_blank"><i :class="`fa fa-${site}`"></i></a>
                  </li>
                </ul>
              </div>
              <div style="margin-top: 1rem" v-html="mover.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      mover: [],
      regions: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/movers/${this.$route.params.id}`)
        .then((response) => {
          this.mover = response.data.mover;
          this.regions = response.data.regions;
          this.loading = false;
        });
    },
  },

  computed: {},
};
</script>

<style lang="scss">
.movers-content {
  border-top: solid 1px #eee;
  background: rgba(#f8f8f8, 0.4);
  padding-top: 20px;

  .sponsored {
    float: right;
    background: #3d0859;
    color: #fff;
    padding: 5px 10px;
  }

  .socials {
    a {
      color: #3d0859;
      border: solid 1px #3d0859;
      margin-right: 1rem;
      border-radius: 50%;
      padding: 5px 8px;

      i {
        font-size: 16px;
      }
    }
  }
}
</style>
